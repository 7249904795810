import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@ion/env';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { initDatadog } from './datadog';

// Turn on Datadog only when `enableDatadog` config is true
if (environment.enableDatadog) {
  initDatadog();
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
